import _ from 'lodash';
import { New, Old } from './audit-log.interface';
import dayjs from '@/lib/dayjsConfig';

const transformToViewFormat = (
  value: {
    label: string;
    previous: string | any;
    current: string | any;
  },
  t: any
): {
  label: string;
  previous: string;
  current: string;
} | null => {
  switch (value.label) {
    case 'endAt':
      value.label = t('Forms.endAt.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY h:mm A') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY h:mm A') : '---';
      return value;
    case 'notes':
      value.label = t('Forms.notes.label');
      return value;
    case 'status':
      value.label = t('Forms.status.label');
      return value;
    case 'clockIn':
      value.label = t('Forms.clockInTime.label');
      return value;
    case 'uci':
      value.label = t('Forms.uciNumber.label');
      return value;
    case 'startAt':
      value.label = t('Forms.startAt.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY h:mm A') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY h:mm A') : '---';
      return value;
    case 'clockOut':
      value.label = t('Forms.clockOutTime.label');
      return value;
    case 'deletedAt':
      value.label = t('Forms.deletedAt.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY h:mm A') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY h:mm A') : '---';
      return value;
    case 'updatedAt':
      value.label = t('Forms.updatedAt.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY h:mm A') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY h:mm A') : '---';
      return value;
    case 'cancelledAt':
      value.label = t('Forms.cancelledAt.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY h:mm A') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY h:mm A') : '---';
      return value;
    case 'googleMeetUrl':
      value.label = t('Forms.googleMeetUrl.label');
      return value;
    case 'lastRepeatDate':
      value.label = t('Forms.lastRepeatDate.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY') : '---';
      return value;
    case 'recurrenceType':
      value.label = t('Forms.recurrenceType.label');
      return value;
    case 'clientSignatureUrl':
      value.label = t('Forms.clientSignatureUrl.label');
      return value;
    case 'name':
      value.label = t('Forms.name.label');
      return value;
    case 'address':
      value.label = t('Forms.address.label');
      return value;
    case 'monthly':
      value.label = t('Forms.monthly.label');
      return value;
    case 'frequency':
      value.label = t('Forms.frequency.label');
      return value;
    case 'languages':
      value.label = t('Forms.languages.label');
      value.current = value.current && Array.isArray(value.current) ? value.current.join(', ') : '---';
      value.previous = value.previous && Array.isArray(value.current) ? value.current.join(', ') : '---';
      return value;
    case 'isExtended':
      value.label = t('Forms.isExtended.label');
      value.current = value.current ? t('Common.yes') : t('Common.no');
      value.previous = value.previous ? t('Common.yes') : t('Common.no');
      return value;
    case 'reservedTo':
      value.label = t('Forms.reservedTo.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY') : '---';
      return value;
    case 'dateEntered':
      value.label = t('Forms.dateEntered.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY') : '---';
      return value;
    case 'authorizedTo':
      value.label = t('Forms.authorizedTo.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY') : '---';
      return value;
    case 'geofencingOn':
      value.label = t('Forms.geofencingOn.label');
      value.current = value.current ? t('Common.yes') : t('Common.no');
      value.previous = value.previous ? t('Common.yes') : t('Common.no');
      return value;
    case 'inPersonOnly':
      value.label = t('Forms.inPersonOnly.label');
      value.current = value.current ? t('Common.yes') : t('Common.no');
      value.previous = value.previous ? t('Common.yes') : t('Common.no');
      return value;
    case 'programNumber':
      value.label = t('Forms.programNumber.label');
      return value;
    case 'authorizedFrom':
      value.label = t('Forms.authorizedFrom.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY') : '---';
      return value;
    case 'initialVisitDate':
      value.label = t('Forms.initialVisitDate.label');
      value.current = value.current ? dayjs(value.current).format('MM.DD.YYYY') : '---';
      value.previous = value.previous ? dayjs(value.previous).format('MM.DD.YYYY') : '---';
      return value;
    case 'authorizationStatus':
      value.label = t('Forms.authorizationStatus.label');
      return value;
    case 'therapist':
      value.label = t('Forms.therapist.label');
      value.current = value.current ? `${value.current.firstName} ${value.current.lastName}` : '---';
      value.previous = value.previous ? `${value.previous.firstName} ${value.previous.lastName}` : '---';
      return value;
    case 'client':
      value.label = t('Forms.client.label');
      value.current = value.current ? `${value.current.firstName} ${value.current.lastName}` : '---';
      value.previous = value.previous ? `${value.previous.firstName} ${value.previous.lastName}` : '---';
      return value;
    case 'serviceRole':
      value.label = t('Forms.serviceRole.label');
      value.current = value.current ? value.current.map((sr: {title: string}) => sr.title).join(', ') : '---';
      value.previous = value.previous ? value.previous.map((sr: {title: string}) => sr.title).join(', ') : '---';
      return value;
    default:
      return null;
  }
};

// Function to compare and get differences
export const compareObjects = (
  previous: Old,
  current: New,
  t: any
): {
  label: string;
  previous: Partial<Old>;
  current: Partial<New>;
}[] => {
  const differences: {
    label: string;
    previous: Partial<Old>;
    current: Partial<New>;
  }[] = [];

  if (!_.isEmpty(previous)) {
    _.forOwn(current, (value, key) => {
      if (!_.isEqual(value, (previous as any)[key])) {
        console.log('DEBUG LOG: key', key);

        const parsed = transformToViewFormat(
          {
            label: key as string,
            previous: (previous as any)[key],
            current: value,
          },
          t
        );
        if (parsed) {
          differences.push(parsed as any);
        }
      }
    });
    return differences;
  } else {
    return [];
  }
};
